import React, { useState, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';

const LazyHome = React.lazy(() => import('./components/Home'));
const LazyContact = React.lazy(() => import('./components/Contact'));
const LazyNavbar = React.lazy(() => import('./components/Navbar'));
const LazyFooter = React.lazy(() => import('./components/Footer'));
const LazyPlans = React.lazy(() => import('./components/Plans'));
const LazyTeam = React.lazy(() => import('./components/Team'));
const LazyTerms = React.lazy(() => import('./components/Terms'));
const LazyPrivacy = React.lazy(() => import('./components/Privacy'));
const LazyFaq = React.lazy(() => import('./components/faq'));
const LazyPd1 = React.lazy(() => import('./components/Pd1'));
const LazyPd2 = React.lazy(() => import('./components/Pd2'));
const LazyPd3 = React.lazy(() => import('./components/Pd3'));
const LazyDownload = React.lazy(() => import('./components/Download'));
const LazyScrollToTop = React.lazy(() => import('./components/ScrollToTop'));

const App = () => {
  const location = useLocation();
  const [isHome, setIsHome] = useState(false);

  useEffect(() => {
    setIsHome(location.pathname === '/');
  }, [location]);

  useEffect(() => {
    if (!isHome) {
      setVideoTimerVal(false); // Reset videoTimerVal to false when route changes, except on the home page
    }
  }, [isHome]);

  const [navbarHeight, setNavbarHeight] = useState(0);
  const [videoTimerVal, setVideoTimerVal] = useState(false);

  const handleNavbarHeightChange = (height) => {
    setNavbarHeight(height);
  };

  const handleVideoTimerChange = (timerVal) => {
    setVideoTimerVal(timerVal);
  };

  const isDownloadPage = location.pathname === '/download';

  if (!isHome) {
    document.body.classList.remove('no--scroll');
  }

  return (
    <>
      <Suspense>
        <LazyNavbar onNavbarHeightChange={handleNavbarHeightChange} videoTimerVal={videoTimerVal} isHome={location.pathname === '/'} />
        <Routes>
          <Route path="/" element={<LazyHome onVideoTimerChange={handleVideoTimerChange} />} />
          <Route path="/about" element={<Navigate to="/" />} />
          <Route path="/contact" element={<LazyContact />} />
          <Route path="/plans" element={<LazyPlans />} />
          <Route path="/team" element={<LazyTeam />} />
          <Route path="/terms" element={<LazyTerms />} />
          <Route path="/privacy" element={<LazyPrivacy />} />
          <Route path="/learn" element={<LazyPd1 />} />
          <Route path="/connect" element={<LazyPd2 />} />
          <Route path="/manage" element={<LazyPd3 />} />
          <Route path="/download" element={<LazyDownload />} />
          <Route path="/faq" element={<LazyFaq navbarHeight={navbarHeight} />} />
        </Routes>
        {!isDownloadPage && <LazyFooter />}
        <LazyScrollToTop />
      </Suspense>
    </>
  );
};

export default App;
